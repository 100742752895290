import { D_VERIFY_STAFF_INVITE, D_FORGOT_PASSWORD_SUBMIT } from '../../utilities/action-types'
import { verifyTransportationUsers } from '@/services/users-service'

export default {
  name: 'confirm',
  props: {},
  data () {
    return {
      isRegistrationLink: false,
      isInvitationLink: false,
      isPasswordResetLink: false,
      isTransportationProfileInvitationLink: false,
      isLoading: false,
      codeVerified: null,
      confirmError: '',
      password: '',
      passwordRules: [(v) => {
        let result = true
        if (v) {
          if (v.length < 8) {
            result = 'Enter 8 characters/numbers or more'
          } else if (v !== this.confirmedPassword) {
            result = 'Password and Confirmed Password don\'t match'
          } else if (/[a-z]+/.test(v)) {
            result = !/[A-Z]+/.test(v) && !/[0-9]/.test(v) && !/[\W]/.test(v) ? 'Enter at least one uppercase letter, number or special character' : true
          } else if (/[0-9]+/.test(v)) {
            result = !/[a-zA-Z\W]+/.test(v) ? 'Enter at least one upper or lower case letter or special character' : true
          }
        }
        return result
      }],
      confirmedPassword: '',
      confirmedPasswordRules: [(v) => {
        let result = true
        if (v) {
          if (v.length < 8) {
            result = 'Enter 8 characters/numbers or more'
          } else if (v !== this.password) {
            result = 'Password and Confirmed Password don\'t match'
          } else if (/[a-z]+/.test(v)) {
            result = !/[A-Z]+/.test(v) && !/[0-9]/.test(v) && !/[\W]/.test(v) ? 'Enter at least one uppercase letter, number or special character' : true
          } else if (/[0-9]+/.test(v)) {
            result = !/[a-zA-Z\W]+/.test(v) ? 'Enter at least one upper or lower case letter or special character' : true
          }
        }
        return result
      }]
    }
  },
  watch: {
    password () {

    },
    confirmedPassword () {

    },
    v (val) {
      // console.log('V', val)
    }
  },
  mounted () {
    // console.log('this.$props', this.$props)
    this.isRegistrationLink = (this.$route.query.r === '1')
    this.isTransportationProfileInvitationLink = (this.$route.query.tv === '1')
    this.isInvitationLink = (this.$route.query.v === '1')
    this.isPasswordResetLink = (this.$route.query.p === '1')
  },
  methods: {
    validLink () {
      return this.isRegistrationLink || this.isInvitationLink || this.isTransportationProfileInvitationLink || this.isPasswordResetLink
    },
    validForm () {
      return this.$refs && this.$refs.form && this.$refs.form.validate()
    },
    submit () {
      if (this.validForm() && this.validLink() && !this.isLoading) {
        const result = this.passwordRules[0](this.password)
        if (typeof result === 'string' || !result) {
          this.confirmError = typeof result === 'string' ? result : `Please follow password hints to enter a stronger password`
          return false
        }

        let params = {
          verificationToken: this.$route.query.code,
          password: this.password,
          confirmedPassword: this.confirmedPassword
        }

        let promise
        this.isLoading = true

        if (this.isRegistrationLink) {
          promise = this.$store.dispatch(D_VERIFY_STAFF_INVITE, params).then((result) => {
            this.codeVerified = result.success
          }).catch((err) => {
            this.confirmError = `Confirmation error: ${typeof err === 'object' ? err.error.message : JSON.stringify(err)}`
          })
        } else if (this.isInvitationLink) {
          promise = this.$store.dispatch(D_VERIFY_STAFF_INVITE, params).then((result) => {
            this.confirmError = ''
            // eslint-disable-next-line no-unneeded-ternary
            this.codeVerified = result.success
          }).catch((err) => {
            this.confirmError = `Confirmation error: ${typeof err === 'object' ? err.error.message : JSON.stringify(err)}`
          })
        } else if (this.isTransportationProfileInvitationLink) {
          promise = verifyTransportationUsers(params).then((result) => {
            this.confirmError = ''
            // eslint-disable-next-line no-unneeded-ternary
            this.codeVerified = result.success
          }).catch((err) => {
            this.confirmError = `Confirmation error: ${typeof err === 'object' ? err.error.message : JSON.stringify(err)}`
          })
        } else if (this.isPasswordResetLink) {
          promise = this.$store.dispatch(D_FORGOT_PASSWORD_SUBMIT, params).then((result) => {
            this.confirmError = ''
            console.log('result', result)
            // eslint-disable-next-line no-unneeded-ternary
            this.codeVerified = result.success
          }).catch((err) => {
            this.confirmError = `Password reset error: ${typeof err === 'object' ? err.error.message : JSON.stringify(err)}`
          })
        }

        promise ? promise.finally(() => {
          this.isLoading = false
        }) : this.isLoading = false
      }
    }
  }
}
