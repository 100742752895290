<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-layout column align-center>
                <div style="text-align: center">
                    <router-link :to="{ name: 'home' }"><img src="@/assets/logo.png" style="width: 350px;" alt="SmartTransit" class="mb-5"></router-link>
                </div>
                <v-flex xs12 sm8 md12>
                    <v-card class="elevation-12" style="width: 400px">
                        <v-form @submit.prevent="submit" ref="form">
                            <v-card-text class="text-xs-center">
                                <div v-if="isRegistrationLink"><h3>Registration Confirmation</h3></div>
                                <div v-if="isInvitationLink || isTransportationProfileInvitationLink"><h3>Invitation Confirmation</h3></div>
                                <div v-if="isPasswordResetLink"><h3>Forgot Password Reset</h3></div>
                                <v-alert :value="!validLink()" type="warning">
                                    Unknown confirmation type, please refer to the link in your email again.
                                </v-alert>
                                <v-alert :value="codeVerified === true" type="success">
                                    Code verified
                                </v-alert>
                                <v-alert :value="confirmError" v-html="confirmError" type="error" />
                                <v-alert :value="codeVerified === false" type="error">
                                    Please try a different confirmation code, the one provided may have expired or is incorrect
                                </v-alert>
                                <p v-if="codeVerified === true">
                                    You can now <v-btn href="/signout">sign in</v-btn> to use your new role
                                </p>
                                <div v-if="validForm() && !codeVerified">
                                    <h4 mb-2 v-if="!isPasswordResetLink" class="text-xs-center">If you are a new user please create your password here:</h4>
                                    <h4 mb-2 v-if="isPasswordResetLink" class="text-xs-center">Please enter your new password</h4>
                                    <v-text-field mb-2 type="password" :required="isPasswordResetLink" v-model="password" hint="Minimum 8 characters long with at least 1 number or uppercase letter" label="Enter a password"></v-text-field>
                                    <v-text-field type="password" :required="isPasswordResetLink" v-model="confirmedPassword" label="Confirm password"></v-text-field>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn v-if="!codeVerified" :disabled="!validForm() || !validLink() || isLoading" :loading="isLoading" type="submit" block color="primary">Confirm</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-slide-y-transition>
    </v-container>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
